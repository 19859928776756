import UserTypeWrapper from '#/utils/UserTypeWrapper';
import { UserTasksBrandContainer } from './components/UserTasksBrandContainer';
import { UserTaskRetailerContainer } from './components/UserTasksRetailerContainer';

const UserTasks = () => (
  <UserTypeWrapper
    agency={<UserTasksBrandContainer />}
    brand={<UserTasksBrandContainer />}
    retailer={<UserTaskRetailerContainer />}
  />
);

export default UserTasks;
