import { useGetFilteredTasksQuery, useGetTaskFiltersQuery } from '../../../../store/api/tasks/tasks';
import Loader from '#/components/common/loader/loader';
import SearchFilters from '#/components/common/SearchFilters/SearchFilters';
import useSearchFilters from '#/components/common/SearchFilters/useSearchFilters.hook';
import { Table } from '#/components/common/table/tableComponent/Table';
import { TasksTableColumns } from './taskTable';
import { CampaignListModes } from '#/components/common/TabModeToggle';
import { useEffect } from 'react';
export const TasksRetailer = () => {
  const { data: filterData, isError: filterDataIsError } = useGetTaskFiltersQuery({});

  const { actions, state } = useSearchFilters(
    {
      viewMode: CampaignListModes.LIST,
      filters: [{ key: 'status', value: 'OPEN', operation: '=' }],
      alwaysActiveFilters: [],
    },
    true,
    filterData?.filters,
  );

  const {
    data: tasksData,
    isError: tasksIsError,
    isFetching: tasksIsFetching,
    isLoading: tasksIsLoading,
    refetch,
  } = useGetFilteredTasksQuery({
    pageNumber: state.pageNumber,
    numberOfRows: state.numberOfElementsPerPage,
    searchQuery: state.searchQuery,
  });

  useEffect(() => {
    refetch();
  }, [state.pageNumber, state.numberOfElementsPerPage, state.searchQuery, refetch]);

  if (tasksIsLoading) return <Loader />;

  const { RetailerTaskTable } = TasksTableColumns(undefined, filterData ? filterData.filters : []);

  return (
    <SearchFilters
      columnNames={filterData?.filters}
      currentIndex={tasksData?.currentIndex}
      filterDataIsError={filterDataIsError}
      hideViewMode
      isError={tasksIsError}
      isFetching={tasksIsFetching}
      isNoResults={!tasksData || tasksData.userTaskProjections.length === 0}
      totalPages={tasksData?.totalPages}
      totalResults={tasksData?.totalResults}
      {...state}
      {...actions}
    >
      <Table
        columns={RetailerTaskTable}
        data={tasksData?.userTaskProjections || []}
        displayStatusColor
        sortProps={{
          sortParams: state.sortParams,
          setSortParams: actions.setSortParam,
        }}
      />
    </SearchFilters>
  );
};
