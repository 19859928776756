import { EditGeneralOverview, ITaskRetailer, Task, Tasks } from '#/store/api/tasks/interfaces';

import { baseApi } from '../../base';
import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';

const tasksApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<Task[], { status: string }>({
      query: ({ status }) => {
        switch (status) {
          case 'open':
            return 'retailer/task/open/all';
          case 'closed':
            return 'retailer/task/closed/all';
          default:
            return 'retailer/task/all';
        }
      },
      providesTags: ['CURRENT_TASK'],
    }),
    getTask: builder.query({
      query: ({ taskId }) => `retailer/task/${taskId}`,
    }),

    getTaskFilters: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/task/filters`,
    }),

    getFilteredTasks: builder.query<
      Tasks<ITaskRetailer>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/task?${searchParams}`;
      },
      providesTags: ['TASKS'],
    }),

    getBrandFilteredTasks: builder.query<
      Tasks<ITaskRetailer>,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `/retailer/task/brand?${searchParams}`;
      },
      providesTags: ['TASKS'],
    }),

    putTask: builder.mutation<void, { taskId: string | undefined; body: EditGeneralOverview }>({
      query: ({ taskId, body }) => ({
        url: `retailer/task/${taskId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['CURRENT_TASK']),
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTaskQuery,
  usePutTaskMutation,
  useGetFilteredTasksQuery,
  useGetTaskFiltersQuery,
  useGetBrandFilteredTasksQuery,
} = tasksApi;

export { tasksApi };
