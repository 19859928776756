import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import CheckGreyIcon from '../../../../assets/CheckTrueGreyIcon.svg';
import { useGetPendingTasksQuery } from '../../../../store/api/dashboard/dashboard';
import DisplayPageName from '../../../common/displayPageName/DisplayPageName';
import Loader from '../../../common/loader/loader';
import { NoDataMessage } from '../../../common/noDataMessage/NoDataMessage';
import { useSnackbarError } from '../../../common/snackbar/useSnackbarError';
import { generatePendingTasksData } from '../../reports/utils';
import {
  marginBottom,
  pendingTasksNoDataMessageSx,
  usePendingTasksStyles,
  useQuickLinksStyles,
} from '../Dashboard.styles';

export const PendingTasks = () => {
  const quickLinksClasses = useQuickLinksStyles();
  const pendingTaskClasses = usePendingTasksStyles();

  const { setError } = useSnackbarError();

  const { data, isLoading, isFetching, error, isError, refetch } = useGetPendingTasksQuery();

  if (isLoading) {
    return (
      <Box className={quickLinksClasses.wrapperDiv}>
        <Loader />
      </Box>
    );
  }

  if (isError) {
    setError(error);
  }

  if (!data || data?.data.length === 0) {
    return (
      <NoDataMessage
        customStyle={pendingTasksNoDataMessageSx}
        dataLoading={isFetching}
        message="No Pending Tasks Available"
        refetch={refetch}
      />
    );
  }
  const tasks = generatePendingTasksData(data);

  return (
    <Box className={quickLinksClasses.wrapperDiv}>
      <DisplayPageName
        customStyles={marginBottom}
        customVariant="H4_Base"
        dataLoading={isFetching}
        imgSrc={CheckGreyIcon}
        pageName="Pending Tasks"
        refetch={refetch}
      />
      <Box className={quickLinksClasses.linksContainer}>
        {tasks.map((task) => {
          const encodedTaskName = encodeURIComponent(`${task.name}^=`);
          return (
            <Link
              key={task.name}
              className={pendingTaskClasses.row}
              to={`/tasks/all?taskDisplayName=${encodedTaskName}`}
            >
              <p className={pendingTaskClasses.text}>{task.name}</p>
              <Box className={pendingTaskClasses.count}>
                <p className={pendingTaskClasses.countText}>{task.count}</p>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};
