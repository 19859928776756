import { Box, Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import CompanyLogo from '#/components/shared/companyLogo/CompanyLogo';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { IChangeCompanyUserForm } from '../../../../interfaces/forms/changeCompany';
import {
  useGetCompanyAccountUserCompanyQuery,
  usePutChangeUserCompanyMutation,
} from '../../../../store/api/company/company';
import { createPartnersCompanyLink } from '../../../../utils/utils';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useUserDataStyles } from './UserData.style';
import { gridSpaceBetweenSx } from '../channelPartners/users/AddChannelPartnerUser.style';
import Select from '../../../common/inputs/Select/Select';
import { useGetCompaniesQuery } from '#/store/api/company/company';

interface UserDataProps {
  companyUserId: string | undefined;
}

const UserCompany: FC<UserDataProps> = ({ companyUserId }) => {
  const [edit, setEdit] = useState(false);

  const { userId } = useParams();
  const classes = useUserDataStyles();
  const { data: companiesData } = useGetCompaniesQuery();

  const { data, refetch, isLoading, isFetching } = useGetCompanyAccountUserCompanyQuery({
    userId,
    companyUserId,
  });

  if (!data) {
    return null;
  }

  const { companyType, companyName, logoId, companyId } = data;

  const { handleSubmit, reset, control, formState, setValue, watch } = useForm<IChangeCompanyUserForm>({
    defaultValues: {
      company: companyId || '',
    },
  });

  const selectedCompany = watch('company');

  useEffect(() => {
    setValue('company', companyId);
  }, [companyId, setValue]);

  const [trigger, { isLoading: putCompanyUserContactTypeLoading, error }] = usePutChangeUserCompanyMutation({
    fixedCacheKey: 'user-data-put',
  });

  const onSubmit = handleSubmit(async (formData: IChangeCompanyUserForm) => {
    if (!isEqual(formData, formState.defaultValues)) {
      try {
        await trigger({ userId, companyId: formData.company });
      } catch (error) {
        return;
      }
    }
    setEdit(false);
  });

  return (
    <Card>
      <TopSection
        dataLoading={isLoading}
        editMode={edit}
        error={error}
        isLoading={putCompanyUserContactTypeLoading}
        onSubmit={onSubmit}
        refetch={refetch}
        requiredPermission={USER_PERMISSIONS.PARTNERS_USERS_BILLING_SECTION_WRITE}
        reset={reset}
        sectionName="Change company"
        setEditMode={setEdit}
      />
      {isFetching ? (
        <Loader />
      ) : (
        <Grid className={classes.viewGrid} container spacing={1}>
          <Box className={classes.compactWrap}>
            <CompanyLogo companyName={companyName} imageId={logoId} size="small" viewOnly />
            <TypographyWithFontFamily cName={classes.customHrefColor} variant={'H4_Base'}>
              <Link to={createPartnersCompanyLink(companyType, companyId)}>{companyName}</Link>
            </TypographyWithFontFamily>
          </Box>
          <Grid item sx={gridSpaceBetweenSx} xs={2.4}>
            <Controller
              control={control}
              name={'company'}
              render={({ field: { onChange } }) => (
                <Select
                  editMode={edit}
                  label="Company"
                  onChange={onChange}
                  options={
                    companiesData?.map((item) => ({
                      label: item.companyName,
                      value: item.companyId,
                    })) || []
                  }
                  value={selectedCompany}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default UserCompany;
