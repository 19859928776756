import { getAggregateColumnMapper } from '#/constants/tables/taskTable/tasksTable';
import { TableCellTypes, TableColumnAlign, ITableColumnsProps } from '#/interfaces/table/table';
import { ITaskRetailer } from '#/store/api/tasks/interfaces';
import { camelOrPascalToRegular } from '#/utils/textFormatters';
import { formatDateAndTime } from '#/utils/utils';
import { flow } from 'lodash';

export const TasksTableColumns = (
  brandOrderTextFormatter?: (text: string | number) => string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columNames: any = [],
) => {
  const sortableColumns = columNames.map((el) => el.columnName);

  const RetailerTaskTable: ITableColumnsProps<ITaskRetailer>[] = [
    {
      fieldName: 'displayName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/tasks/overview',
      actionElementIdentifier: 'aggregateId',
      sortable: sortableColumns.includes('displayName'),
    },
    {
      fieldName: 'targetObjectId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Target Object Id',
      linkable: false,
      actionElementIdentifier: 'targetObjectId',
      sortable: sortableColumns.includes('targetObjectId'),
    },
    {
      fieldName: 'targetObjectName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Target Object',
      linkable: true,
      baseUrl: '/instances/channel-tactics',
      actionElementIdentifier: 'targetObjectId',
      sortable: sortableColumns.includes('targetObjectName'),
    },
    {
      fieldName: 'targetObjectType',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Target Type',
      hide: false,
      sortable: sortableColumns.includes('targetObjectType'),
      textFormatter: flow(getAggregateColumnMapper, camelOrPascalToRegular),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      linkable: false,
      actionElementIdentifier: 'status',
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'submittedAction',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Submitted Action',
      linkable: false,
      actionElementIdentifier: 'submittedAction',
      sortable: sortableColumns.includes('submittedAction'),
    },
    {
      fieldName: 'lastUpdated',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Last Updated',
      linkable: false,
      textFormatter: formatDateAndTime,
      actionElementIdentifier: 'lastUpdated',
      sortable: sortableColumns.includes('lastUpdated'),
    },
  ];

  const BrandTaskTable: ITableColumnsProps<ITaskRetailer>[] = [
    {
      fieldName: 'displayName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Name',
      linkable: true,
      baseUrl: '/tasks/overview',
      actionElementIdentifier: 'aggregateId',
      sortable: sortableColumns.includes('displayName'),
    },
    {
      fieldName: 'targetObjectId',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Target Object Id',
      linkable: false,
      actionElementIdentifier: 'targetObjectId',
      sortable: sortableColumns.includes('targetObjectId'),
    },
    {
      fieldName: 'targetObjectName',
      align: TableColumnAlign.LEFT,
      type: TableCellTypes.STRING,
      customHead: 'Target Object',
      linkable: true,
      baseUrl: '/instances/channel-tactics',
      actionElementIdentifier: 'targetObjectId',
      sortable: sortableColumns.includes('targetObjectName'),
    },
    {
      fieldName: 'targetObjectType',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Target Type',
      hide: false,
      sortable: sortableColumns.includes('targetObjectType'),
      textFormatter: flow(getAggregateColumnMapper, camelOrPascalToRegular),
    },
    {
      fieldName: 'status',
      align: TableColumnAlign.CENTER,
      type: TableCellTypes.STRING,
      customHead: 'Status',
      linkable: false,
      actionElementIdentifier: 'status',
      sortable: sortableColumns.includes('status'),
    },
    {
      fieldName: 'lastUpdated',
      align: TableColumnAlign.RIGHT,
      type: TableCellTypes.STRING,
      customHead: 'Last Updated',
      linkable: false,
      textFormatter: formatDateAndTime,
      actionElementIdentifier: 'lastUpdated',
      sortable: sortableColumns.includes('lastUpdated'),
    },
  ];

  return {
    RetailerTaskTable,
    BrandTaskTable,
  };
};

export const TASKS_ALL = 'ALL';
