import { IUserProfile } from '#/interfaces/campaigns/campaigns';

import { SearchFilters } from '#/store/api/campaigns/campaignsInterfaces';
import {
  CompanyUsers,
  ISingleUserData,
  IUser,
  IUserView,
  PostUser,
  PutUser,
  UserInfo,
  UserType,
} from '#/store/api/users/usersInterfaces';
import { baseApi } from '#/store/base';

// TODO: check getCompanyUserAll is invalidated when user changes name
const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUserProfile: builder.query<IUser, void>({
      query: () => `/user/user/profile`,
    }),
    getUserProfile: builder.query<IUserProfile, { userId: string }>({
      query: ({ userId }) => `/user/user/${userId}/profile`,
    }),
    getUser: builder.query<ISingleUserData, { userId: string | undefined }>({
      query: ({ userId }) => `user/user/${userId}`,
      providesTags: ['USER_DETAILS'],
    }),
    getUserInfo: builder.query<UserInfo, { userId: string | undefined }>({
      query: ({ userId }) => `user/user/${userId}/info`,
    }),
    getCompanyUserAll: builder.query<
      CompanyUsers,
      {
        pageNumber: number;
        numberOfRows: number;
        searchQuery: string;
      }
    >({
      query: ({ pageNumber, numberOfRows, searchQuery = '' }) => {
        const searchParams = new URLSearchParams();

        searchParams.set('pageNumber', pageNumber.toString());
        searchParams.set('numberOfRows', numberOfRows.toString());
        searchParams.set('searchQuery', searchQuery);

        return `retailer/company-user?${searchParams}`;
      },
      providesTags: ['USER_DATA', 'COMPANY_USERS'],
    }),
    getCompanyUserAllFilters: builder.query<{ filters: SearchFilters[] }, unknown>({
      query: () => `/retailer/company-user/filters`,
    }),

    putActivateUser: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `/user/user/activate-user`,
        method: 'PUT',
        body: { userId },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA', 'COMPANY_USERS']),
    }),
    deleteInactivateUser: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `/user/user/inactivate-user`,
        method: 'DELETE',
        body: { userId },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA', 'COMPANY_USERS']),
    }),
    postUser: builder.mutation<void, { body: PostUser }>({
      query: ({ body }) => ({
        method: 'POST',
        url: `user/user`,
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA']),
    }),
    putUser: builder.mutation<void, { userId: string | undefined; body: PutUser }>({
      query: ({ userId, body }) => ({
        method: 'PUT',
        url: `user/user/${userId}`,
        body,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA']),
    }),

    putClaimsSet: builder.mutation<void, { userId: string | undefined; selectedCompanyToAssociate: string[] }>({
      query: ({ userId, selectedCompanyToAssociate }) => ({
        method: 'PUT',
        url: `user/user/claims/set`,
        body: {
          userId: userId,
          claims: {
            companyIds: selectedCompanyToAssociate,
          },
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA']),
    }),

    putUserRoles: builder.mutation<void, { userId: string | undefined; roleId: string | undefined }>({
      query: ({ userId, roleId }) => ({
        method: 'PUT',
        url: `user/user/role`,
        body: { id: userId, roleId },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['USER_DATA']),
    }),

    // available options: super_admin, retailer, brand, agency, channel-partner
    getUserRoles: builder.query<UserType[], { userType: string }>({
      query: ({ userType }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('type', userType);
        return `user/role/?${searchParams}`;
      },
    }),
    getAllUsersAll: builder.query<IUserView[], { userType: string }>({
      query: ({ userType }) => `user/user/all?userTypes=${userType}`,
      providesTags: ['USER_DATA', 'COMPANY_USERS'],
    }),
    getCategoryManagersRole: builder.query<IUserView[], { role: string }>({
      query: ({ role }) => `user/user/by-role?role=${role}`,
      providesTags: ['USER_DATA', 'COMPANY_USERS'],
    }),
  }),
});

export const {
  useLazyGetCurrentUserProfileQuery,
  useGetUserProfileQuery,
  useGetUserQuery,
  useGetCompanyUserAllQuery,
  useGetCompanyUserAllFiltersQuery,
  usePutActivateUserMutation,
  useDeleteInactivateUserMutation,
  usePutUserMutation,
  usePostUserMutation,
  usePutUserRolesMutation,
  useGetUserRolesQuery,
  useGetAllUsersAllQuery,
  usePutClaimsSetMutation,
  useGetCategoryManagersRoleQuery,
} = usersApi;

export { usersApi };
