import { useParams } from 'react-router';

import { useGetUserQuery } from '#/store/api/users/users';

import { useGetCompanyAccountUserCompanyQuery } from '../../../../store/api/company/company';
import Loader from '../../../common/loader/loader';
import UserData from './UserData';
import UserCompany from './UserCompany';

const PartnerUserCompanyInfo = () => {
  const { userId } = useParams();

  const { data: userData } = useGetUserQuery({ userId });
  const {
    data: getCompanyAccountUserCompanyData,
    isLoading,
    isError: getCompanyAccountUserCompanyDataError,
  } = useGetCompanyAccountUserCompanyQuery({
    userId,
    companyUserId: userData?.companyId,
  });

  const isError = getCompanyAccountUserCompanyDataError || !getCompanyAccountUserCompanyData;

  if (isLoading) return <Loader />;
  if (isError) return null;

  return (
    <>
      <UserData companyUserId={userData?.companyId} />
      <UserCompany companyUserId={userData?.companyId} />
    </>
  );
};

export default PartnerUserCompanyInfo;
