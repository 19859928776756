import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useSnackbarSuccess } from '#/components/common/snackbar/useSnackbarSuccess';
import { BASE_PATH } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import { EditGeneralOverview } from '#/store/api/tasks/interfaces';
import { usePutTaskMutation } from '#/store/api/tasks/tasks';

export const useTaskAction = (aggregateId: string | undefined, firstAction: string) => {
  const [updateTask, { isLoading }] = usePutTaskMutation();
  const navigate = useNavigate();

  const schema = yup
    .object()
    .shape({
      comment: yup.string().notRequired(),
      action: yup.string().required(),
    })
    .required();
  const { clearErrors, control, handleSubmit } = useForm<EditGeneralOverview>({
    resolver: yupResolver(schema),
    defaultValues: {
      action: firstAction,
    },
  });

  const { setError } = useSnackbarError();
  const { setSuccess } = useSnackbarSuccess();

  const fetchEditGeneralOverview = async <T extends EditGeneralOverview>(data: T) => {
    try {
      await updateTask({ taskId: aggregateId, body: data });
      setSuccess('Task Action Successful');
      navigate(`${BASE_PATH}/${ROUTE_PATHS.TASKS}/${ROUTE_PATHS.TASKS_ALL}`);
    } catch (error: unknown) {
      setError(error);
    }
  };

  const onSubmit = handleSubmit(async (formData: EditGeneralOverview) => {
    await fetchEditGeneralOverview(formData);
    clearErrors();
  });

  return {
    control,
    isLoading,
    onSubmit,
  };
};
