import { merge } from 'lodash';

const brandConstants = {
  COLOR_PRIMARY: '#5846CB',
  TABLE_HEAD_COLOR: '#f2f4fa',
  COLOR_PRIMARY_SUCCESS: '#008000',
  COLOR_PRIMARY_ERROR: '#FF0000',
  COLOR_SECONDARY: '#f50aa2',

  FONT_XXS: '0.625rem', // 10px
  FONT_XS: '0.75rem', // 12px
  FONT_SM: '0.875rem', // 14px
  FONT_MD: '1rem', // 16px
  FONT_LG: '1.25rem', // 20px
  FONT_XL: '1.5rem', // 24px
  FONT_XXL: '2.25rem', // 36px,
};

const defaultTHEME = {
  PALETTES: {
    UI: {
      Primary: {
        Base: '#5846CB',
        100: '#F1F0F7',
        200: '#EBE9F9',
        300: '#D3CEF1',
        400: '#A69DE2',
        500: '#7C6FD7',
        600: '#5846CB',
        700: '#43369F',
        800: '#332B70',
        900: '#232038',
      },
      Secondary: {
        Base: '#1F8BC9',
        100: '#EBF6FD',
        200: '#D8EDFA',
        300: '#B7DEF5',
        400: '#80BFE3',
        500: '#48A8E0',
        600: '#1F8BC9',
        700: '#176593',
        800: '#094C74',
        900: '#022F4A',
      },
      background: '#F0F4F9',
      Neutral: {
        Base: '#606670',
        100: '#F1F4F9',
        200: '#E4E8EF',
        300: '#C9CFD7',
        400: '#A1A8B2',
        500: '#81878F',
        600: '#606670',
        700: '#444C57',
        800: '#282D34',
        900: '#16191F',
      },
    },
    SEMANTIC: {
      Error: {
        100: '#F5E9EC',
        300: '#EBA7B7',
        500: '#E83661',
        700: '#A81436',
        900: '#59081B',
      },
      Warning: {
        100: '#FFF1D6',
        300: '#FFD584',
        500: '#FFB82F',
        700: '#EB9B00',
        900: '#A46C00',
      },
      Success: {
        100: '#DFF6EC',
        300: '#A1E9CB',
        500: '#1BBF7A',
        700: '#087949',
        900: '#013922',
      },
      Information: {
        100: '#E7F0F3',
        300: '#B6D1DC',
        500: '#609BB3',
        700: '#1A53A8',
        900: '#002357',
      },
    },
    CHARTS: {
      COMPLETED: '#1EC6B1',
      BOOKED: '#4C1F70',
      PENDING: '#BC55B2',
      TO_GOAL: '#E4E8EF',
      DELIVERED: '#1AB7DB',
      FAILED: '#F37A36',
      OPENED: '#1E4FAF',
    },
    LEGEND: {
      ACCEPTED: '#1CBF7A',
      DELIVERED: '#4C88E0',
      FAILED: '#FEB82F',
      OPENED: '#1E4FAF',
    },
    TEXT: {
      Dark: '#16191F', // Default
      Gray: '#606670',
      Green: '#2ba633',
      Medium: '#A1A8B2',
      Light: '#FFFFFF',
      Action_Primary: '#5140C5',
      Action_Secondary: '#1F8AC9',
      Error: '#E83661',
      Warning: '#FFBF19',
      Success: '#1BBF7A',
      Info: '#1A53A8',
      Black: '#000000',
    },
    BORDER: {
      Dark: '#444C57',
      Medium: '#A1A8B2',
      Light: '#C9CFD7',
      Extra_Light: '#E4E8EF',
      Selected: '#B7DEF5',
      Active: '#80BFE3',
    },
    ICONS: {
      Dark: '#444C57',
      Default: '#81878F',
      Medium: '#C9CFD7',
      Light: '#FFFFFF',
      Action_Primary: '#5846CB',
      Action_Secondary: '#1F8AC9',
      Error: '#E83661',
      Warning: '#FFB82F',
      Success: '#1BBF7A',
      Info: '#4D88E1',
    },
    OTHER: {
      White: '#FFFFFF',
    },
    STATUS: {
      PENDING: '#FFB82F',
      PENDING_REVIEW: '#FFB82F',
      REJECTED: '#FF6C19',
      CANCELLED: '#E83661',
      CANCELED: '#E83661',
      DELETED: '#B01416',
      COMPLETED: '#4D88E1',
      COMPLETE: '#4D88E1',
      ACTIVE: '#1BBF7A',
      CONFIRMED: '#1BBF7A',
      BOOKED: '#1BBF7A',
      BRAND_ACCEPT: '#1BBF7A',
      RETAILER_ACCEPT: '#1BBF7A',
      BRAND_REQUEST_REVISION: '#FFB82F',
      BRAND_APPLY: '#FFB82F',
      CREATED: '#FFB82F',
      RETAILER_DECLINE: '#FF6C19',
      BRAND_DECLINE: '#FF6C19',
    },
  },
  FONT_SIZE: {
    xxl: brandConstants.FONT_XXL,
    xl: brandConstants.FONT_XL,
    lg: brandConstants.FONT_LG,
    md: brandConstants.FONT_MD,
    sm: brandConstants.FONT_SM,
    xs: brandConstants.FONT_XS,
    xxs: brandConstants.FONT_XXS,
  },
  FONT_WEIGHTS: {
    LIGHT: 300,
    REGULAR: 400,
    MEDIUM: 500,
    SEMIBOLD: 600,
    BOLD: 700,
    BLACK: 900,
  },
  REM_SPACES: {
    0.25: '0.25rem',
    0.5: '0.5rem',
    1: '1rem',
    1.5: '1.5rem',
    2: '2rem',
    2.5: '2.5rem',
    3: '3rem',
    3.5: '3.5rem',
    4: '4rem',
    4.5: '4.5rem',
    5: '5rem',
    5.5: '5.5rem',
    6: '6rem',
    7: '7rem',
    8: '8rem',
    9: '9rem',
    10: '10rem',
    15: '15rem',
  },
  BREAKPOINTS: {
    xs: 0,
    sm: 768,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
  DEFAULT_SPACING: [6, 8, 10, 12, 14, 16, 18, 20],
} as const;

export const THEME = merge(defaultTHEME, window.customTheme);
export type THEME = typeof THEME;
export default brandConstants;
