import { Grid } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { TopSection } from '#/components/campaigns/topSection/TopSection';
import Card from '#/components/common/card/card';
import Loader from '#/components/common/loader/loader';
import { USER_PERMISSIONS } from '#/constants/permissions/userPermissions';

import { IAddChannelPartnerUserForm } from '../../../../interfaces/forms/addChanelPartnerUser';
import {
  useGetCompanyAccountUserCompanyQuery,
  usePutCompanyUserContactTypeMutation,
} from '../../../../store/api/company/company';
import { convertAllCapsUnderscoreStrings } from '../../../../utils/utils';
import Checkbox from '../../../common/inputs/Checkbox/Checkbox';
import { TypographyWithFontFamily } from '../../../common/typography/TypographyWithFontFamily';
import { useUserDataStyles } from './UserData.style';
import { gridSpaceBetweenSx } from '../channelPartners/users/AddChannelPartnerUser.style';
import Select from '../../../common/inputs/Select/Select';
import { useGetUserRolesQuery, useGetUserQuery, usePutUserRolesMutation } from '#/store/api/users/users';
import { UsersType } from '#/constants/usersRoles/usersRole';

interface UserDataProps {
  companyUserId: string | undefined;
}

const UserData: FC<UserDataProps> = ({ companyUserId }) => {
  const [edit, setEdit] = useState(false);

  const { userId } = useParams();
  const classes = useUserDataStyles();

  const { data, refetch, isLoading, isFetching } = useGetCompanyAccountUserCompanyQuery({
    userId,
    companyUserId,
  });

  if (!data) {
    return null;
  }

  const { data: userRolesRetailerData } = useGetUserRolesQuery({ userType: UsersType.RETAILER });
  const { data: userRolesChannelPartnerData } = useGetUserRolesQuery({ userType: UsersType.CHANNEL_PARTNER });
  const { data: userRolesBrandData } = useGetUserRolesQuery({ userType: UsersType.BRAND });
  const { data: userInfoData } = useGetUserQuery({ userId });

  const { companyType } = data;

  const { handleSubmit, reset, control, formState, setValue } = useForm<IAddChannelPartnerUserForm>({
    defaultValues: {
      keyContact: data.keyContact,
      billingContact: data.billingContact,
      defaultRole: userInfoData?.roleName || '',
    },
  });

  useEffect(() => {
    if (userInfoData) {
      let userRoleId = '';

      if (userRolesRetailerData && userRolesRetailerData.length > 0) {
        userRoleId = userRolesRetailerData.find((user) => user.name === userInfoData?.roleName)?.id || '';
      }

      if (!userRoleId && userRolesBrandData && userRolesBrandData.length > 0) {
        userRoleId = userRolesBrandData.find((user) => user.name === userInfoData?.roleName)?.id || '';
      }

      if (!userRoleId && userRolesChannelPartnerData && userRolesChannelPartnerData.length > 0) {
        userRoleId = userRolesChannelPartnerData.find((user) => user.name === userInfoData?.roleName)?.id || '';
      }

      if (userRoleId) {
        setValue('defaultRole', userRoleId);
      }
    }
  }, [userInfoData, userRolesRetailerData, userRolesBrandData, userRolesChannelPartnerData, setValue]);

  const [trigger, { isLoading: putCompanyUserContactTypeLoading, error }] = usePutCompanyUserContactTypeMutation({
    fixedCacheKey: 'user-data-put',
  });

  const [triggerSecond] = usePutUserRolesMutation({
    fixedCacheKey: 'user-data-second-put',
  });

  const onSubmit = handleSubmit(async (formData: IAddChannelPartnerUserForm) => {
    if (!isEqual(formData, formState.defaultValues)) {
      try {
        await trigger({ userId, userFormData: formData });
        const roleId = formData.defaultRole || '';
        await triggerSecond({ userId, roleId });
      } catch (error) {
        return;
      }
    }
    setEdit(false);
  });

  const readOnly = !edit;

  return (
    <Card>
      <TopSection
        dataLoading={isLoading}
        editMode={edit}
        error={error}
        isLoading={putCompanyUserContactTypeLoading}
        onSubmit={onSubmit}
        refetch={refetch}
        requiredPermission={USER_PERMISSIONS.PARTNERS_USERS_BILLING_SECTION_WRITE}
        reset={reset}
        sectionName="Organizational Details"
        setEditMode={setEdit}
      />
      {isFetching ? (
        <Loader />
      ) : (
        <Grid className={classes.viewGrid} container spacing={1}>
          <Grid item xs={2}>
            <TypographyWithFontFamily variant="Label_Base">Company Type</TypographyWithFontFamily>
            <TypographyWithFontFamily>{convertAllCapsUnderscoreStrings(companyType)}</TypographyWithFontFamily>
          </Grid>
          {userInfoData?.roleName === 'Brand User' ? (
            <Grid item sx={gridSpaceBetweenSx} xs={2.4}>
              <Controller
                control={control}
                name={'defaultRole'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    editMode={edit}
                    label="Role"
                    onChange={onChange}
                    options={userRolesBrandData?.map((item) => ({ label: item.name, value: item.id })) || []}
                    value={value}
                  />
                )}
              />
            </Grid>
          ) : userInfoData?.roleName === 'Channel Partner User' ? (
            <Grid item sx={gridSpaceBetweenSx} xs={2.4}>
              <Controller
                control={control}
                name={'defaultRole'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    editMode={edit}
                    label="Role"
                    onChange={onChange}
                    options={userRolesChannelPartnerData?.map((item) => ({ label: item.name, value: item.id })) || []}
                    value={value}
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item sx={gridSpaceBetweenSx} xs={2.4}>
              <Controller
                control={control}
                name={'defaultRole'}
                render={({ field: { onChange, value } }) => (
                  <Select
                    editMode={edit}
                    label="Role"
                    onChange={onChange}
                    options={userRolesRetailerData?.map((item) => ({ label: item.name, value: item.id })) || []}
                    value={value}
                  />
                )}
              />
            </Grid>
          )}
          <Grid alignSelf="center" item xs={1}>
            <Controller
              control={control}
              name={'keyContact'}
              render={({ field }) => (
                <Checkbox label="Key Contact" onChange={field.onChange} readOnly={readOnly} value={field.value} />
              )}
            />
          </Grid>
          <Grid alignSelf="center" item xs={1}>
            <Controller
              control={control}
              name={'billingContact'}
              render={({ field }) => (
                <Checkbox label="Billing Contact" onChange={field.onChange} readOnly={readOnly} value={field.value} />
              )}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export default UserData;
