import { Box, Grid } from '@mui/material';
import { flow } from 'fp-ts/function';
import { FC, useEffect, useState } from 'react';

import TextField from '#/components/common/inputs/TextField/TextField';
import TextFieldOld from '#/components/common/inputs/TextFieldOld/TextFieldOld';

import PermissionWrapper from '#/utils/PermissionWrapper';
import { createFormatterWithReverse, currencyFormatter, extractNumber, numberFormatter } from '#/utils/textFormatters';

import EditIcon from '../../../assets/Edit.svg';
import { USER_PERMISSIONS } from '../../../constants/permissions/userPermissions';
import { getUserHasPermission } from '../../../constants/permissions/utils';
import { useAddTireInstancesForm } from '../../../hooks/forms/campaigns/useAddTireInstanceForm';
import { IChannelAndTacticsResponseTactic, IChannelAndTacticsResponseTier } from '../../../interfaces/forms/campaigns';
import { styles } from '../../campaigns/AddDetailsToSpecificTier.styles';
import NewButton from '../../common/button/NewButton';
import AddTacticsToTierInstance from './AddTacticsToTierInstance';
import { useCampaignTierStyle } from './CampaignTier.style';
import CopyTierInstance from './CopyTierInstance';
import NewCampaignTierTacticsTableInstance from './instances/channelsAndTactics/NewCampaignTierTacticsTableInstances';

interface ICampaignTierInstanceProps {
  tier?: IChannelAndTacticsResponseTier;
  fetchTiers?: () => Promise<void>;
  userHasCreatePermission?: boolean;
  isOnInstance?: boolean;
}
const CampaignTierInstance: FC<ICampaignTierInstanceProps> = ({
  tier,
  userHasCreatePermission,
  isOnInstance = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const { reset, errors, register, watch, setValue, onSubmitEdit } = useAddTireInstancesForm(() => setEditMode(false));
  const [isAddTacticsModalOpen, setIsAddTacticsModalOpen] = useState(false);
  useEffect(() => {
    const tempTargetTotal = watch('targetBudget') * watch('targetBrands');
    if (!isNaN(tempTargetTotal)) {
      setValue('targetTotal', tempTargetTotal);
    }
  }, [watch('targetBudget'), watch('targetBrands')]);
  const setInitialValues = () => {
    reset({
      tierId: tier?.tierId,
      targetBudget: tier?.targetBudget,
      targetBrands: tier?.numberOfBrands,
      tierName: tier?.tierName,
    });
  };
  useEffect(() => {
    setInitialValues();
  }, [tier?.tierId, tier?.tierName, tier?.targetBudget, tier?.targetBrands]);
  const onAddTactics = () => {
    setIsAddTacticsModalOpen((prev: boolean) => !prev);
  };
  const getUniqueTactics = (tierTactics: IChannelAndTacticsResponseTactic[]) => {
    return Array.from(
      new Set(
        tierTactics?.map((obj) =>
          JSON.stringify({
            channel: obj.channel,
            subChannel: obj.subChannel,
            area: obj.tactic,
            channelPartnerId: obj.channelPartnerId,
          }),
        ),
      ).values(),
    ).map((str) => JSON.parse(str));
  };
  const userHasEditTierShellPermission = getUserHasPermission(USER_PERMISSIONS.CAMPAIGN_CHANNEL_TACTICS_WRITE);
  const userHasEditTierInstancePermission = getUserHasPermission(
    USER_PERMISSIONS.CAMPAIGN_INSTANCE_CHANNEL_TACTICS_WRITE,
  );
  const classes = useCampaignTierStyle();

  return (
    <Box>
      <Box className={classes.tierContainer}>
        <Box>
          <form className={classes.form} onSubmit={onSubmitEdit}>
            <Grid columnGap={'0.5rem'} container>
              <Grid item xs={2.9}>
                <TextFieldOld
                  editMode={editMode}
                  error={errors.tierName?.message}
                  label="TIER NAME"
                  register={register('tierName')}
                  value={tier?.tierName}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBudget?.message}
                  formHooks={{
                    register: register('targetBudget'),
                    setValue: (value) => setValue('targetBudget', parseFloat(value)),
                  }}
                  formatters={{
                    preview: currencyFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, currencyFormatter), extractNumber),
                  }}
                  label="TARGET BUDGET"
                  value={watch('targetBudget') ? String(watch('targetBudget')) : ''}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled={!editMode}
                  error={errors.targetBrands?.message}
                  formHooks={{
                    register: register('targetBrands'),
                    setValue: (value) => setValue('targetBrands', parseFloat(value)),
                  }}
                  formatters={{
                    preview: numberFormatter,
                    typing: createFormatterWithReverse(flow(extractNumber, numberFormatter), extractNumber),
                  }}
                  label="TARGET BRANDS"
                  value={watch('targetBrands') !== undefined ? String(watch('targetBrands')) : ''}
                />
              </Grid>
              <Grid item xs={2.9}>
                <TextField
                  disabled
                  formatters={{
                    preview: currencyFormatter,
                  }}
                  label="TARGET TOTAL"
                  value={watch('targetTotal')?.toString()}
                />
              </Grid>
            </Grid>
            <PermissionWrapper requiredPermissions={[USER_PERMISSIONS.INSTANCE_CHANNEL_AND_TACTICS_TACTIC_MODAL_WRITE]}>
              <div className={classes.bottomButtons}>
                {editMode ? (
                  <Box className={classes.cancelContainer}>
                    <NewButton
                      onClick={() => {
                        setEditMode(false);
                        setInitialValues();
                      }}
                      sx={styles.cancelButton}
                      text="Cancel"
                      variant="Secondary"
                    />
                    <NewButton text="Save" type="submit" variant="Primary" />
                  </Box>
                ) : (
                  <>
                    {((userHasEditTierShellPermission && !isOnInstance) ||
                      (userHasEditTierInstancePermission && isOnInstance)) && (
                      <NewButton
                        icon={EditIcon}
                        iconPosition="left"
                        onClick={() => {
                          setEditMode(true);
                        }}
                        text="Edit"
                        variant="Ghost"
                      />
                    )}
                  </>
                )}
              </div>
            </PermissionWrapper>
          </form>
        </Box>
        <Box className={classes.tacticsTableContainer}>
          <NewCampaignTierTacticsTableInstance tactics={tier?.tactics} tierId={tier?.tierId} />
        </Box>
        {userHasCreatePermission && (
          <Box className={classes.copyButtonsContainer}>
            <NewButton icon={EditIcon} iconPosition="left" onClick={onAddTactics} text="Edit Tactics" variant="Ghost" />
            <CopyTierInstance isOnInstance={isOnInstance} tierCopy={tier?.tierId} />
          </Box>
        )}
      </Box>

      {isAddTacticsModalOpen && (
        <AddTacticsToTierInstance
          isAddTacticsModalOpen={isAddTacticsModalOpen}
          savedTactics={tier?.tactics ? getUniqueTactics(tier?.tactics) : []}
          setIsAddTacticsModalOpen={setIsAddTacticsModalOpen}
          tierId={tier?.tierId}
          tierName={tier?.tierName}
        />
      )}
    </Box>
  );
};
export default CampaignTierInstance;
