import { Box, Menu, MenuItem } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';

import AlertError from '#/components/common/Alert/AlertError/AlertError';
import ConfirmationModal from '#/components/common/ConfirmationModal/ConfirmationModal';
import NewButton from '#/components/common/button/NewButton';
import { useSnackbarError } from '#/components/common/snackbar/useSnackbarError';
import { useCampaignInstanceHeaderButtonsStyles } from '#/components/features/campaigns/CampaignHeaderButtons/CampaignInstanceHeaderButtons.styles';
import { BASE_PATH } from '#/constants/general';
import { ROUTE_PATHS } from '#/constants/routing/routePaths';

import useCloneCampaign from '#/hooks/useCloneCampaign.hook';
import useGetResource from '#/hooks/useGetResource';
import {
  usePostCampaignInstanceReactivateMutation,
  useGetCampaignInstanceIdsQuery,
} from '#/store/api/campaigns/campaigns';

import {
  usePostCampaignCancelMutation,
  usePostCampaignCloseMutation,
  usePostCampaignDeleteMutation,
} from '#/store/api/campaigns/campaigns';

type CampaignInstanceHeaderButtonsProps = {
  name: string;
  actions: string[];
  instanceStatus?: string;
  brandId?: string;
};

const CampaignInstanceHeaderButtons: FC<CampaignInstanceHeaderButtonsProps> = ({
  name = '',
  actions = [],
  instanceStatus,
  brandId,
}) => {
  const { COMMUNICATIONS, CAMPAIGNS_INVITE_BRAND, CAMPAIGNS_ALL, CAMPAIGNS } = ROUTE_PATHS;

  const resource = useGetResource();
  const { setError } = useSnackbarError();
  const classes = useCampaignInstanceHeaderButtonsStyles();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const [postCampaignCancel, { isLoading: isPostCampaignCancelLoading, error: postCampaignCancelError }] =
    usePostCampaignCancelMutation();
  const [postCampaignClose, { isLoading: isPostCampaignCloseLoading, error: postCampaignCloseError }] =
    usePostCampaignCloseMutation();
  const [postCampaignInstanceReactivate, { isLoading: reactivateIsLoading }] =
    usePostCampaignInstanceReactivateMutation();
  const [postCampaignDelete, { isLoading: isDeleteLoading }] = usePostCampaignDeleteMutation();

  const {
    loading: isCloneCampaignLoading,
    cloneCampaignHandler,
    error: cloneCampaignHandlerError,
  } = useCloneCampaign(resource.resourceId);

  const { data: resourceData } = useGetCampaignInstanceIdsQuery({ campaignId: resource.resourceId });

  const error = postCampaignCancelError || postCampaignCloseError || cloneCampaignHandlerError;

  const openMenuHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const promoteCampaignHandler = () => {
    if (resource.resourceId) {
      navigate(`${BASE_PATH}/${COMMUNICATIONS}/${CAMPAIGNS_INVITE_BRAND}?campaigns=${resource.resourceId}`);
    }
  };

  const cancelCampaignHandler = async () => {
    try {
      await postCampaignCancel({ resource }).unwrap();
      navigate(`${BASE_PATH}/${CAMPAIGNS}/${CAMPAIGNS_ALL}`, { replace: true });
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    }
    try {
    } catch (error) {}
  };

  const closeCampaignHandler = async () => {
    try {
      await postCampaignClose({ resource }).unwrap();
      navigate(`${BASE_PATH}/${CAMPAIGNS}/${CAMPAIGNS_ALL}`, { replace: true });
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    }
    try {
    } catch (error) {}
  };

  const buttonsWithoutPromote = actions.filter((el) => el !== 'PROMOTE') || [];

  const shouldShowMenu = buttonsWithoutPromote?.length > 1;
  const shouldShowPromote = actions.includes('PROMOTE');
  const isMenuOpen = Boolean(anchorEl);

  const handleReactivateOrder = async () => {
    const validBrandId = brandId ?? 'DEFAULT_BRAND_ID';
    if (validBrandId === 'DEFAULT_BRAND_ID') {
      console.error('Error: Brand ID is missing.');
      return;
    }
    try {
      await postCampaignInstanceReactivate({ resource, brandId: validBrandId }).unwrap();
      navigate(`${BASE_PATH}/${CAMPAIGNS}/${CAMPAIGNS_ALL}`, { replace: true });
    } catch (error) {
      console.error('Error reactivating campaign:', error);
    }
  };

  const deleteCampaignHandler = async () => {
    try {
      await postCampaignDelete({ resource }).unwrap();
      navigate(`${BASE_PATH}/${CAMPAIGNS}/${CAMPAIGNS_ALL}`, { replace: true });
    } catch (error) {
      setError((error as FetchBaseQueryError).data);
    }
    try {
    } catch (error) {}
    setIsDeleteModalOpen(false);
  };

  const isInstanceIdsEmpty = !resourceData?.instanceIds?.length;

  const BUTTON_MAPPER = {
    PROMOTE: (
      <NewButton
        className={classes.promote}
        onClick={promoteCampaignHandler}
        text="Promote Campaign"
        variant="Primary"
      />
    ),
    CANCEL: (isMenuItem) => (
      <NewButton
        className={classNames(classes.button, classes.cancel)}
        loading={isPostCampaignCancelLoading}
        onClick={() => setIsCancelModalOpen(true)}
        text={isMenuItem ? 'Cancel Campaign' : 'Cancel Order'}
        variant="Secondary"
      />
    ),
    REACTIVATE: (
      <NewButton
        className={classNames(classes.button, classes.reactivate)}
        disabled={reactivateIsLoading}
        onClick={() => setIsReactivateModalOpen(true)}
        text="Reactivate Order"
        variant="Secondary"
      />
    ),
    CLONE: (
      <NewButton
        className={classes.button}
        loading={isCloneCampaignLoading}
        onClick={cloneCampaignHandler}
        text="Clone Campaign"
        variant="Secondary"
      />
    ),
    CLOSE: (
      <NewButton
        className={classNames(classes.button, classes.cancel)}
        loading={isPostCampaignCloseLoading}
        onClick={() => setIsCloseModalOpen(true)}
        text="Close Campaign"
        variant="Secondary"
      />
    ),
    DELETE: (
      <NewButton
        className={classNames(classes.button, classes.delete)}
        loading={isDeleteLoading}
        onClick={() => setIsDeleteModalOpen(true)}
        text="Delete Campaign"
        variant="Secondary"
      />
    ),
  };
  return (
    <Box className={classes.buttonsContainer}>
      <AlertError error={error} />
      {shouldShowPromote && BUTTON_MAPPER['PROMOTE']}
      {instanceStatus === 'CANCELED' && BUTTON_MAPPER['REACTIVATE']}
      {!shouldShowMenu &&
        (buttonsWithoutPromote[0] === 'CANCEL'
          ? BUTTON_MAPPER['CANCEL'](false)
          : BUTTON_MAPPER[buttonsWithoutPromote[0]])}
      {shouldShowMenu && (
        <>
          <NewButton
            aria-controls={isMenuOpen ? 'basic-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
            aria-haspopup="true"
            className={classes.moreActions}
            customId="basic-button"
            onClick={openMenuHandler}
            text="More Actions"
            variant={'Secondary'}
          />
          <Menu
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PopoverClasses={{
              paper: classes.menu,
            }}
            anchorEl={anchorEl}
            id="basic-menu"
            onClose={closeMenuHandler}
            open={isMenuOpen}
          >
            {buttonsWithoutPromote.map((button) => {
              return (
                <MenuItem key={button}>
                  {button === 'CANCEL' ? BUTTON_MAPPER[button](true) : BUTTON_MAPPER[button]}
                </MenuItem>
              );
            })}
            {isInstanceIdsEmpty && <MenuItem key="DELETE">{BUTTON_MAPPER['DELETE']}</MenuItem>}
          </Menu>
        </>
      )}
      <ConfirmationModal
        actionCallback={handleReactivateOrder}
        handleCloseModal={() => setIsReactivateModalOpen(false)}
        open={isReactivateModalOpen}
        warningMessage={`Are you sure you want to REACTIVATE "${name}" ?`}
      />
      <ConfirmationModal
        actionCallback={cancelCampaignHandler}
        handleCloseModal={setIsCancelModalOpen}
        open={isCancelModalOpen}
        warningMessage={`Are you sure you want to CANCEL "${name}" ?`}
      />
      <ConfirmationModal
        actionCallback={closeCampaignHandler}
        handleCloseModal={setIsCloseModalOpen}
        open={isCloseModalOpen}
        warningMessage={`Are you sure you want to CLOSE campaign "${name}"? This will cancel all pending brand orders.`}
      />
      <ConfirmationModal
        actionCallback={deleteCampaignHandler} // Ensure delete handler is correctly passed
        handleCloseModal={() => setIsDeleteModalOpen(false)} // Ensure modal closes after action
        open={isDeleteModalOpen}
        warningMessage={`Are you sure you want to DELETE campaign "${name}"? This action cannot be undone.`}
      />
    </Box>
  );
};

export default CampaignInstanceHeaderButtons;
